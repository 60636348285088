/**
 * Class to define inheritance of load retrieve strategy.
 * The process can be async load (lazy) or sync load
 *
 * There are methods that must be implemented at consumer level
 * To retrieve study call execLoad
 */
export default class RetrieveMetadataLoader {
  /**
   * @constructor
   * @param {Object} server Object with server configuration parameters
   * @param {Array} studyInstanceUID Study instance ui to be retrieved
   * @param {Object} [filters] - Object containing filters to be applied on retrieve metadata process
   * @param {string} [filter.seriesInstanceUID] - series instance uid to filter results against
   */
  constructor(server, studyInstanceUID, filters = {}) {
    this.server = server;
    this.studyInstanceUID = studyInstanceUID;
    this.filters = filters;
  }

  async execLoad() {
    await this.configLoad();
    const beta = JSON.parse(sessionStorage.getItem('beta'))
    let complements = []

    let storageComplementos = sessionStorage.getItem('complementos')

    if (storageComplementos !== undefined
      && storageComplementos !== null
      && storageComplementos !== ''
      && storageComplementos !== 'undefined'
      && storageComplementos !== 'null') {
      complements = JSON.parse(sessionStorage.getItem('complementos'))

    }

    let postLoadData = {}

    if (beta) {
      const preLoadData = await this.preLoad();
      let loadData = await this.load(preLoadData);

      if (loadData.sopInstances.length === 0) {
        preLoadData.seriesData.splice(0, 1)
        preLoadData.seriesInstanceUIDsMap.splice(0, 1)
        loadData = undefined

        for (const i of preLoadData.seriesData) {
          loadData = await this.load(preLoadData);

          console.log(loadData.sopInstance)

          if (loadData.sopInstances.length > 0) {
            break
          }
        }
      }

      postLoadData = await this.posLoad(loadData);

      if (complements.length > 0) {
        const { complementSeries, seriesMap } = await this.getComplementsData();

        complementSeries.forEach((series, idx) => {
          postLoadData.series.push(series)
          postLoadData.seriesMap[series.SeriesInstanceUID] = seriesMap[series.SeriesInstanceUID]
        })
      }
    } else {
      postLoadData = await this.getMetadata();
    }

    return postLoadData;
  }

  /**
   * It iterates over given loaders running each one. Loaders parameters must be bind when getting it.
   * @param {Array} loaders - array of loader to retrieve data.
   */
  async runLoaders(loaders) {
    let result;
    for (const loader of loaders) {
      try {
        result = await loader();
        if (result && result.length) {
          break; // closes iterator in case data is retrieved successfully
        }
      } catch (e) {
        throw e;
      }
    }

    if (loaders.next().done && !result) {
      throw new Error('RetrieveMetadataLoader failed');
    }

    return result;
  }

  // Methods to be overwrite
  async configLoad() { }
  async preLoad() { }
  async load(preLoadData) { }
  async posLoad(loadData) { }
}
