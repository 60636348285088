import './camera-button.styl';

import { Icon } from './../elements/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { getEnabledElement } from '../../../../extensions/cornerstone/src/state';

export function CameraButton(props) {
  const { icon, viewportIndex } = props;
  const className = classnames(props.className);
  const iconProps = typeof icon === 'string' ? { name: icon } : icon;

  const handleClick = event => {
    const activeViewport = getEnabledElement(viewportIndex);
    let canvas = activeViewport.children[0];

    let data = canvas.toDataURL('image/jpeg');
    window.parent.postMessage({ type: 1, image: data }, '*')
  };

  return (
    <div
      className={className}
      onClick={handleClick}
    >
      {iconProps && <Icon {...iconProps} />}
    </div>
  );
}

CameraButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ])
};

CameraButton.defaultProps = {
  className: 'camera-button',
};

export default CameraButton;
