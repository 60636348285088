import { redux, OHIF } from '@ohif/core';
import store from './../../store';

const { studyMetadataManager } = OHIF.utils;

function setWindowLevelModality(modality) {
  let windowLevelData = {}

  switch (modality) {
    case 'CT':
      windowLevelData = {
        1: { description: 'Abdomen', window: '400', level: '60' },
        2: { description: 'Angio', window: '600', level: '300' },
        3: { description: 'Bone', window: '1500', level: '300' },
        4: { description: 'Brain', window: '80', level: '40' },
        5: { description: 'Chest', window: '400', level: '40' },
        6: { description: 'Lungs', window: '1500', level: '-400' },
      }
      break
    case 'MG':
      windowLevelData = {
        1: { description: '[30/60]', window: '60', level: '30' },
        2: { description: '[125/250]', window: '250', level: '125' },
        3: { description: '[500/1000]', window: '1000', level: '500' },
        4: { description: '[1875/3750]', window: '3750', level: '1875' },
        5: { description: '[3750/7500]', window: '7500', level: '3750' },
        6: { description: '[7500/15000]', window: '15000', level: '7500' },
        7: { description: '[15000/30000]', window: '30000', level: '15000' },
        8: { description: '[30000/60000]', window: '60000', level: '30000' }
      }
      break
    default:
      windowLevelData = {
        1: { description: '[20/40]', window: '40', level: '20' },
        2: { description: '[40/80]', window: '80', level: '40' },
        3: { description: '[80/160]', window: '160', level: '80' },
        4: { description: '[160/320]', window: '320', level: '160' },
        5: { description: '[320/640]', window: '640', level: '320' },
        6: { description: '[640/1280]', window: '1280', level: '640' },
        7: { description: '[1280/2560]', window: '2560', level: '1280' },
        8: { description: '[2560/5120]', window: '5120', level: '2560' }
      }
      break
  }

  return windowLevelData
}

const commandsModule = ({ commandsManager }) => {
  const { setViewportActive, setActiveViewportSpecificData } = redux.actions;

  const actions = {
    updateActiveViewport: ({ viewports, direction }) => {
      const { viewportSpecificData, activeViewportIndex } = viewports;
      const maxIndex = Object.keys(viewportSpecificData).length - 1;

      let newIndex = activeViewportIndex + direction;
      newIndex = newIndex > maxIndex ? 0 : newIndex;
      newIndex = newIndex < 0 ? maxIndex : newIndex;

      store.dispatch(setViewportActive(newIndex));
    },
    setWindowLevelPreset: ({ viewports, preset }) => {
      const viewportSpecificData = { ...viewports.viewportSpecificData };
      const activeViewport =
        viewportSpecificData[viewports.activeViewportIndex];
      const studyMetadata = studyMetadataManager.get(
        activeViewport.StudyInstanceUID
      );

      const windowLevelData = setWindowLevelModality(studyMetadata._data.series[0].Modality)

      const { window, level } =
        windowLevelData && windowLevelData[preset];

      if (window && level) {
        commandsManager.runCommand('setWindowLevel', {
          viewports,
          window,
          level,
        });
      }
    },
    updateViewportDisplaySet: ({ viewports, direction }) => {
      const viewportSpecificData = { ...viewports.viewportSpecificData };
      const activeViewport =
        viewportSpecificData[viewports.activeViewportIndex];
      const studyMetadata = studyMetadataManager.get(
        activeViewport.StudyInstanceUID
      );

      if (!studyMetadata) {
        return;
      }

      const allDisplaySets = studyMetadata.getDisplaySets();
      const currentDisplaySetIndex = allDisplaySets.findIndex(
        displaySet =>
          displaySet.displaySetInstanceUID ===
          activeViewport.displaySetInstanceUID
      );
      if (currentDisplaySetIndex < 0) {
        return;
      }

      const newDisplaySetIndex = currentDisplaySetIndex + direction;
      const newDisplaySetData = allDisplaySets[newDisplaySetIndex];
      if (!newDisplaySetData) {
        return;
      }

      store.dispatch(setActiveViewportSpecificData(newDisplaySetData));
    },
  };

  const definitions = {
    // Next/Previous active viewport
    incrementActiveViewport: {
      commandFn: actions.updateActiveViewport,
      storeContexts: ['viewports'],
      options: { direction: 1 },
    },
    decrementActiveViewport: {
      commandFn: actions.updateActiveViewport,
      storeContexts: ['viewports'],
      options: { direction: -1 },
    },
    // Window level Presets
    windowLevelPreset1: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 1 },
    },
    windowLevelPreset2: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 2 },
    },
    windowLevelPreset3: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 3 },
    },
    windowLevelPreset4: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 4 },
    },
    windowLevelPreset5: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 5 },
    },
    windowLevelPreset6: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 6 },
    },
    windowLevelPreset7: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 7 },
    },
    windowLevelPreset8: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 8 },
    },
    windowLevelPreset9: {
      commandFn: actions.setWindowLevelPreset,
      storeContexts: ['viewports'],
      options: { preset: 9 },
    },
    nextViewportDisplaySet: {
      commandFn: actions.updateViewportDisplaySet,
      storeContexts: ['viewports'],
      options: { direction: 1 },
    },
    previousViewportDisplaySet: {
      commandFn: actions.updateViewportDisplaySet,
      storeContexts: ['viewports'],
      options: { direction: -1 },
    },
  };

  return {
    definitions,
    defaultContext: 'VIEWER',
  };
};

export default commandsModule;
