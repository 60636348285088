import cornerstone from 'cornerstone-core';
import cornerstoneTools from '../../cornerstoneTools/cornerstoneTools';
import OHIF from '@ohif/core';

import setCornerstoneLayout from './utils/setCornerstoneLayout.js';
import { getEnabledElement } from './state';
import CornerstoneViewportDownloadForm from './CornerstoneViewportDownloadForm';
const scroll = cornerstoneTools.import('util/scroll');

const { studyMetadataManager } = OHIF.utils;
const { setViewportSpecificData, setImgInfoState } = OHIF.redux.actions;

let activeTool = '';
const removableTools = ['StackScroll', 'Wwwc', 'Zoom', 'Magnify', 'Pan']

const refreshCornerstoneViewports = () => {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    if (enabledElement.image) {
      cornerstone.updateImage(enabledElement.element);
    }
  });
};

let toggleReferenceLines = false;

const synchronizer = new cornerstoneTools.Synchronizer('cornerstonenewimage', cornerstoneTools.updateImageSynchronizer);

const referenceLines = () => {
  const elements = document.getElementsByClassName('viewport-element');

  const firstElem = elements[0];
  const firstElemStackState = cornerstoneTools.getToolState(firstElem, 'stack');
  const firstElemImageIds = firstElemStackState.data[0].imageIds;
  const firstElemStack = {
    currentImageIdIndex: 0,
    imageIds: firstElemImageIds
  };

  // Enable mouse inputs for the first element
  cornerstoneTools.addToolForElement(firstElem, cornerstoneTools.StackScrollTool);
  cornerstoneTools.addToolForElement(firstElem, cornerstoneTools.StackScrollMouseWheelTool);
  cornerstoneTools.setToolEnabledForElement(firstElem, 'StackScroll', { mouseButtonMask: 1 });
  cornerstoneTools.setToolEnabledForElement(firstElem, 'StackScrollMouseWheel', {});

  const config = {
    drawAllMarkers: true
  };

  // Orientation markers config
  const orientation = cornerstoneTools.OrientationMarkersTool

  for (let i = 0; i < elements.length; i++) {
    cornerstone.enable(elements[i]);

    //Enable reference lines according to mouse event
    (function () {
      let element = elements[i];

      element.onmouseover = function (event) {
        cornerstoneTools.setToolDisabledForElement(element, 'ReferenceLines');
      };

      element.onmouseleave = function (event) {
        cornerstoneTools.setToolEnabledForElement(element, 'ReferenceLines');
        cornerstone.updateImage(element);
      };
    })();
  }

  // Load first image from the first element. Enable tools and reference lines.
  cornerstone.loadImage(firstElemImageIds[0]).then(function (image) {
    cornerstone.displayImage(firstElem, image);

    cornerstoneTools.addToolForElement(firstElem, orientation, config);
    cornerstoneTools.setToolEnabledForElement(firstElem, 'OrientationMarkers', { mouseButtonMask: 1 });

    cornerstoneTools.addStackStateManager(firstElem, ['stack', 'referenceLines']);
    cornerstoneTools.addToolState(firstElem, 'stack', firstElemStack);

    const pan = cornerstoneTools.PanTool;
    cornerstoneTools.addToolForElement(firstElem, pan);

    cornerstoneTools.setToolActiveForElement(
      firstElem,
      "Pan",
      {
        mouseButtonMask: 8,
      }
    );
    const zoom = cornerstoneTools.ZoomTool;
    cornerstoneTools.addToolForElement(firstElem, zoom);

    cornerstoneTools.setToolActiveForElement(
      firstElem,
      "Zoom",
      {
        mouseButtonMask: 4,
      }
    );

    cornerstoneTools.setToolActiveForElement(firstElem, 'StackScrollMouseWheel', {});

    synchronizer.add(firstElem);

    cornerstoneTools.addToolForElement(firstElem, cornerstoneTools.ReferenceLinesTool);
    cornerstoneTools.setToolEnabledForElement(firstElem, 'ReferenceLines', {
      synchronizationContext: synchronizer,
    });
  });

  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    if (element !== firstElem) {
      synchronizer.add(element);
      cornerstoneTools.addToolForElement(element, cornerstoneTools.ReferenceLinesTool, {
        synchronizationContext: synchronizer,
      });
      cornerstoneTools.setToolEnabledForElement(element, 'ReferenceLines', {
        synchronizationContext: synchronizer,
      });
      cornerstoneTools.addToolForElement(element, orientation, config);
      cornerstoneTools.setToolEnabledForElement(element, 'OrientationMarkers', { mouseButtonMask: 1 });
    }
  }
}

const commandsModule = ({ servicesManager }) => {
  const actions = {
    rotateViewport: ({ viewports, rotation }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.rotation += rotation;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    flipViewportHorizontal: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.hflip = !viewport.hflip;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    flipViewportVertical: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.vflip = !viewport.vflip;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    scaleViewport: ({ direction, viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      const step = direction * 0.15;

      if (enabledElement) {
        if (step) {
          let viewport = cornerstone.getViewport(enabledElement);
          viewport.scale += step;
          cornerstone.setViewport(enabledElement, viewport);
        } else {
          cornerstone.fitToWindow(enabledElement);
        }
      }
    },
    resetViewport: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        cornerstone.reset(enabledElement);
      }
    },
    invertViewport: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.invert = !viewport.invert;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    // TODO: this is receiving `evt` from `ToolbarRow`. We could use it to have
    //       better mouseButtonMask sets.
    setToolActive: ({ toolName }) => {
      if (!toolName) {
        console.warn('No toolname provided to setToolActive command');
      }

      if (activeTool.length > 0) {
        const elements = document.getElementsByClassName('viewport-element');

        for (let i = 0; i < elements.length; i++) {
          let isToolActive = cornerstoneTools.isToolActiveForElement(elements[i], activeTool)

          if (isToolActive) {
            cornerstoneTools.setToolDisabledForElement(elements[i], activeTool);
          }
        }
      }

      if (removableTools.includes(toolName)) {
        activeTool = toolName;
      }

      cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
    },
    clearAnnotations: ({ viewports }) => {
      const element = getEnabledElement(viewports.activeViewportIndex);
      if (!element) {
        return;
      }

      cornerstoneTools.clearToolState(element, 'RectangleRoi');
      cornerstoneTools.clearToolState(element, 'ArrowAnnotate');
      cornerstoneTools.clearToolState(element, 'Bidirectional');
      cornerstoneTools.clearToolState(element, 'Length');
      cornerstoneTools.clearToolState(element, 'Angle');
      cornerstoneTools.clearToolState(element, 'FreehandRoi');
      cornerstoneTools.clearToolState(element, 'EllipticalRoi');

      cornerstoneTools.setToolDisabledForElement(element, 'ScaleOverlay');
      cornerstoneTools.setToolDisabledForElement(element, 'DragProbe');
      cornerstone.updateImage(element);
    },
    referenceLines: () => {
      toggleReferenceLines = !toggleReferenceLines

      if (toggleReferenceLines) {
        referenceLines();
      } else {
        const elements = document.getElementsByClassName('viewport-element');

        cornerstoneTools.setToolDisabledForElement(elements[0], 'ReferenceLines');
        cornerstoneTools.setToolDisabledForElement(elements[0], 'OrientationMarkers');

        for (var b = 0; b < elements.length; b++) {
          let element = elements[b];

          element.onmouseleave = null;
          element.onmouseover = null

          if (element !== elements[0]) {
            cornerstoneTools.setToolDisabledForElement(element, 'ReferenceLines');
            cornerstoneTools.setToolDisabledForElement(element, 'OrientationMarkers');
          }
        }
      }

      return;
    },
    scaleOverlay: () => {
      const elements = document.getElementsByClassName('viewport-element');
      const overlay = cornerstoneTools.ScaleOverlayTool;

      for (var b = 0; b < elements.length; b++) {
        let element = elements[b];

        cornerstoneTools.addToolForElement(element, overlay);
        cornerstoneTools.setToolActiveForElement(
          element,
          "ScaleOverlay",
          {
            mouseButtonMask: 1,
          }
        );
      }

      return;
    },
    nextImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, 1);
    },
    previousImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, -1);
    },
    getActiveViewportEnabledElement: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      return enabledElement;
    },
    showDownloadViewportModal: ({ title, viewports }) => {
      const activeViewportIndex = viewports.activeViewportIndex;
      const { UIModalService } = servicesManager.services;
      if (UIModalService) {
        UIModalService.show({
          content: CornerstoneViewportDownloadForm,
          title,
          contentProps: {
            activeViewportIndex,
            onClose: UIModalService.hide,
          },
        });
      }
    },
    updateTableWithNewMeasurementData({
      toolType,
      measurementNumber,
      location,
      description,
    }) {
      // Update all measurements by measurement number
      const measurementApi = OHIF.measurements.MeasurementApi.Instance;
      const measurements = measurementApi.tools[toolType].filter(
        m => m.measurementNumber === measurementNumber
      );

      measurements.forEach(measurement => {
        measurement.location = location;
        measurement.description = description;

        measurementApi.updateMeasurement(measurement.toolType, measurement);
      });

      measurementApi.syncMeasurementsAndToolData();

      refreshCornerstoneViewports();
    },
    getNearbyToolData({ element, canvasCoordinates, availableToolTypes }) {
      const nearbyTool = {};
      let pointNearTool = false;

      availableToolTypes.forEach(toolType => {
        const elementToolData = cornerstoneTools.getToolState(
          element,
          toolType
        );

        if (!elementToolData) {
          return;
        }

        elementToolData.data.forEach((toolData, index) => {
          let elementToolInstance = cornerstoneTools.getToolForElement(
            element,
            toolType
          );

          if (!elementToolInstance) {
            elementToolInstance = cornerstoneTools.getToolForElement(
              element,
              `${toolType}Tool`
            );
          }

          if (!elementToolInstance) {
            console.warn('Tool not found.');
            return undefined;
          }

          if (
            elementToolInstance.pointNearTool(
              element,
              toolData,
              canvasCoordinates
            )
          ) {
            pointNearTool = true;
            nearbyTool.tool = toolData;
            nearbyTool.index = index;
            nearbyTool.toolType = toolType;
          }
        });

        if (pointNearTool) {
          return false;
        }
      });

      return pointNearTool ? nearbyTool : undefined;
    },
    removeToolState: ({ element, toolType, tool }) => {
      cornerstoneTools.removeToolState(element, toolType, tool);
      cornerstone.updateImage(element);
    },
    setCornerstoneLayout: () => {
      setCornerstoneLayout();
    },
    setWindowLevel: ({ viewports, window, level }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);

        viewport.voi = {
          windowWidth: Number(window),
          windowCenter: Number(level),
        };
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    jumpToImage: ({
      StudyInstanceUID,
      SOPInstanceUID,
      frameIndex,
      activeViewportIndex,
      refreshViewports = true,
    }) => {
      const study = studyMetadataManager.get(StudyInstanceUID);

      const displaySet = study.findDisplaySet(ds => {
        return (
          ds.images &&
          ds.images.find(i => i.getSOPInstanceUID() === SOPInstanceUID)
        );
      });

      if (!displaySet) {
        return;
      }

      displaySet.SOPInstanceUID = SOPInstanceUID;
      displaySet.frameIndex = frameIndex;

      window.store.dispatch(
        setViewportSpecificData(activeViewportIndex, displaySet)
      );

      if (refreshViewports) {
        refreshCornerstoneViewports();
      }
    },
    toggleInformation: () => {
      let showImageInfo = window.store.getState().viewports.showImageInfo;

      window.store.dispatch(
        setImgInfoState(!showImageInfo)
      );

      let display = window.store.getState().viewports.showImageInfo ? 'block' : 'none';
      const infoElements = document.getElementsByClassName('OHIFCornerstoneViewportOverlay');

      for (let i = 0; i < infoElements.length; i++) {
        infoElements[i].style.display = display;
      }
    },
    showShortcuts: () => {
      const shortcutsMenu = document.getElementsByClassName('shortcutsMenu');
      const tooltip = document.getElementsByClassName('tooltip');

      tooltip[0].style.display = 'none';
      shortcutsMenu[0].style.display = 'block';
    }
  };

  const definitions = {
    jumpToImage: {
      commandFn: actions.jumpToImage,
      storeContexts: [],
      options: {},
    },
    getNearbyToolData: {
      commandFn: actions.getNearbyToolData,
      storeContexts: [],
      options: {},
    },
    removeToolState: {
      commandFn: actions.removeToolState,
      storeContexts: [],
      options: {},
    },
    updateTableWithNewMeasurementData: {
      commandFn: actions.updateTableWithNewMeasurementData,
      storeContexts: [],
      options: {},
    },
    showDownloadViewportModal: {
      commandFn: actions.showDownloadViewportModal,
      storeContexts: ['viewports'],
      options: {},
    },
    getActiveViewportEnabledElement: {
      commandFn: actions.getActiveViewportEnabledElement,
      storeContexts: ['viewports'],
      options: {},
    },
    rotateViewportCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: 90 },
    },
    rotateViewportCCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: -90 },
    },
    invertViewport: {
      commandFn: actions.invertViewport,
      storeContexts: ['viewports'],
      options: {},
    },
    flipViewportVertical: {
      commandFn: actions.flipViewportVertical,
      storeContexts: ['viewports'],
      options: {},
    },
    flipViewportHorizontal: {
      commandFn: actions.flipViewportHorizontal,
      storeContexts: ['viewports'],
      options: {},
    },
    scaleUpViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: 1 },
    },
    scaleDownViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: -1 },
    },
    fitViewportToWindow: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: 0 },
    },
    resetViewport: {
      commandFn: actions.resetViewport,
      storeContexts: ['viewports'],
      options: {},
    },
    clearAnnotations: {
      commandFn: actions.clearAnnotations,
      storeContexts: ['viewports'],
      options: {},
    },
    referenceLines: {
      commandFn: actions.referenceLines,
      storeContexts: [],
      options: {},
    },
    nextImage: {
      commandFn: actions.nextImage,
      storeContexts: ['viewports'],
      options: {},
    },
    previousImage: {
      commandFn: actions.previousImage,
      storeContexts: ['viewports'],
      options: {},
    },
    scaleOverlay: {
      commandFn: actions.scaleOverlay,
      storeContexts: [],
      options: {},
    },
    // TOOLS
    setToolActive: {
      commandFn: actions.setToolActive,
      storeContexts: [],
      options: {},
    },
    setZoomTool: {
      commandFn: actions.setToolActive,
      storeContexts: [],
      options: { toolName: 'Zoom' },
    },
    setCornerstoneLayout: {
      commandFn: actions.setCornerstoneLayout,
      storeContexts: [],
      options: {},
      context: 'VIEWER',
    },
    setWindowLevel: {
      commandFn: actions.setWindowLevel,
      storeContexts: ['viewports'],
      options: {},
    },
    toggleInformation: {
      commandFn: actions.toggleInformation,
      storeContexts: [],
      options: {},
    },
    showShortcuts: {
      commandFn: actions.showShortcuts,
      storeContexts: [],
      options: {},
    }
  };

  return {
    actions,
    definitions,
    defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
  };
};

export default commandsModule;
