import React, { PureComponent } from 'react';

import ToolbarButton from '../../viewer/ToolbarButton';

export class SyncImgButton extends PureComponent {

  onClick = () => {
    if(this.props.onClick){
      this.props.onClick();
    }
  };

  render() {
    return (
      <div className="btn-group">
        <ToolbarButton
          className={this.props.syncImgToolActive ? 'toolbar-button active sync-button' : 'toolbar-button sync-button'}
          label={'Sync I'}
          icon="syncImages"
          onClick={this.onClick}
        />
      </div>
    );
  }
}

export default SyncImgButton;
