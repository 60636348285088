/**
 * VIEWPORT
 */
export const SET_VIEWPORT = 'VIEWPORT::SET';
export const SET_VIEWPORT_ACTIVE = 'VIEWPORT::SET_ACTIVE';
export const SET_VIEWPORT_LAYOUT = 'VIEWPORT::SET_LAYOUT';
export const SET_VIEWPORT_LAYOUT_AND_DATA =
  'VIEWPORT::SET_VIEWPORT_LAYOUT_AND_DATA';
export const CLEAR_VIEWPORT = 'VIEWPORT::CLEAR';
export const SET_SPECIFIC_DATA = 'VIEWPORT::SET_SPECIFIC_DATA';
export const SET_ACTIVE_SPECIFIC_DATA = 'VIEWPORT::SET_ACTIVE_SPECIFIC_DATA';
export const SET_STACK_LAYOUT_STATE = 'VIEWPORT::SET_STACK_LAYOUT_STATE';
export const SET_SELECTED_IMAGES_STATE = 'VIEWPORT::SET_SELECTED_IMAGES_STATE';
export const SET_SYNC_STATE = 'VIEWPORT::SET_SYNC_STATE';
export const SET_IMG_INFO_STATE = 'VIEWPORT::SET_IMG_INFO_STATE';

/**
 * SERVERS
 */
export const ADD_SERVER = 'ADD_SERVER';
export const SET_SERVERS = 'SET_SERVERS';

/**
 * EXTENSIONS
 */
export const SET_EXTENSION_DATA = 'SET_EXTENSION_DATA';

/**
 * PREFERENCES
 * */
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
