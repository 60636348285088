import './print-image.styl';

import PropTypes from 'prop-types';
import React from 'react';
import OHIF from '@ohif/core';
import classnames from 'classnames';
import { getEnabledElement } from '../../../../extensions/cornerstone/src/state';
import { useRef } from 'react';

const { setSelectedImagesState } = OHIF.redux.actions;

export function PrintImageCheckBox(props) {
  const { viewportIndex, viewportData } = props;
  const className = classnames(props.className);
  const checkbox = useRef();
  const SOPInstanceUID = viewportData.displaySet.SOPInstanceUID;

  if (SOPInstanceUID) {
    checkCheckBoxImage();
  }

  function checkCheckBoxImage() {
    const state = window.store.getState();
    let selectedImages = state.viewports.selectedImages;

    if (selectedImages && checkbox) {
      let hasImage = selectedImages.includes(SOPInstanceUID);

      if (checkbox.current) {
        checkbox.current.checked = hasImage;
      }
    }
  }

  const handleClick = event => {
    const activeViewport = getEnabledElement(viewportIndex);
    const enabledElement = cornerstone.getEnabledElement(activeViewport);
    const beta = JSON.parse(sessionStorage.getItem('beta'));

    let image = enabledElement.image.sharedCacheKey;
    let imageLink = '';

    if (beta) {
      imageLink = image.replace('wado?requestType=WADO&', 'client-api/patients/imagesJPEG?');
      imageLink = imageLink.replace('&contentType=application/dicom&transferSyntax=*', '');
    } else {
      imageLink = image.replace('images?requestType=WADO&', 'imagesJPEG?');
      imageLink = imageLink.replace('&contentType=application/dicom', '');
    }

    let selectedImages = {
      SOPInstanceUID: SOPInstanceUID,
      isSelected: checkbox.current.checked
    }

    window.store.dispatch(setSelectedImagesState(selectedImages))
    window.parent.postMessage({ type: 2, isChecked: checkbox.current.checked, image: imageLink }, '*');
  };

  return (
    <div
      className={className}
    >
      <label className="print-container">
        <input type="checkbox" ref={checkbox} onClick={handleClick}></input>
        <span className="checkmark"></span>
      </label>
    </div>
  );
}

PrintImageCheckBox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ])
};

PrintImageCheckBox.defaultProps = {
  className: 'print-image',
};

export default PrintImageCheckBox;
